import React, { CSSProperties, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Popover } from '@headlessui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { NavItemStyles, PopoverWrapper } from './Header/PopoverWrapper';
import { Banner } from './Header/Banner';
import { sm, md, nav, lg } from '~/ds/mixins/breakpoints';
import { Button } from '~/elements/Button';
import { ButtonGroup } from '~/elements/ButtonGroup';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Icon } from '~/elements/Icon';
import { Link } from '~/elements/Link';
import Logo from '~/assets/svg/logo.svg';

interface HeaderStyleProps {
  dark?: boolean;
  scrollBg?: string;
}

export interface HeaderProps extends HeaderStyleProps {
  className?: string;
  style?: CSSProperties;
}

// Styles
const SiteHeader = styled.header<HeaderStyleProps>`
  --header-text-bg: rgba(var(--rgb-black), 0.02);
  --header-text-border: rgba(var(--rgb-black), 0.25);
  --header-scroll-bg: ${(props) => props.scrollBg || 'rgba(var(--rgb-white), .9)'};

  padding: var(--space-4) 0;
  color: var(--color-gray-900);
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  box-shadow: 0;
  background: rgba(var(--rgb-white), 0);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;

  &.scroll-down {
    transform: translate3d(0, -100%, 0);

    &:after {
      opacity: 0;
    }
  }

  &.scroll-up {
    backdrop-filter: blur(12px);
    background: var(--header-scroll-bg);
    padding: var(--space-3) 0;
    box-shadow: 0 1px 0px rgba(var(--rgb-black), 0.03), 0 2px 5px rgba(var(--rgb-black), 0.05);
    transform: translate3d(0, 0, 0);

    &:after {
      opacity: 1;
    }
  }

  ${({ dark }) =>
    dark === true &&
    css`
      --header-bg: rgba(var(--rgb-black), 0.5);
      --header-logo: var(--color-white);
      --header-text: var(--color-white);
      --header-text-bg: rgba(var(--rgb-white), 0.1);
      /* --btn-link-hover: rgba(var(--rgb-white), 0.1); */
      --btn-text-color-secondary: var(--color-white);
      --btn-bg-secondary: rgba(var(--rgb-white), 0.2);
      --btn-bg-secondary-hover: rgba(var(--rgb-white), 0.3);
      --btn-gradient: transparent;
      --btn-text-color-link: var(--color-white);
      background-color: transparent;

      &.scroll-down {
        background-color: transparent;
        backdrop-filter: blur(16px) saturate(125%);
      }

      &.scroll-up {
        transform: translate3d(0, 0, 0);
        background-color: var(--header-bg, --color-black);
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.05), 0 0 1px 2px rgba(0, 0, 0, 0.05);
        backdrop-filter: blur(16px) saturate(125%);
      }

      @supports (backdrop-filter: blur(16px) saturate(125%)) or (-webkit-backdrop-filter: blur(15px) saturate(125%)) {
        &.scroll-up {
          background-color: rgba(var(--rgb-black), 0.95);
          backdrop-filter: blur(16px) saturate(125%);
          background-color: var(--header-bg, --color-black);
        }
      }
    `}
`;

const HeaderContainer = styled.div`
  gap: var(--space-3);
  max-width: var(--screen-lg);
  margin-right: auto;
  margin-left: auto;
  padding-right: var(--space-5);
  padding-left: var(--space-5);
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;

  @media ${lg} {
    padding-right: var(--space-6);
    padding-left: var(--space-6);
  }
`;

const HeaderLogo = styled(Link)`
  display: table;
  position: relative;
  top: -2px;
  color: var(--header-logo);

  svg {
    height: 16px;
  }

  @media ${nav} {
    svg {
      height: 19px;
    }
  }
`;

const NavList = styled.ul`
  display: none;
  flex-grow: 1;
  justify-content: center;

  @media ${nav} {
    display: flex;
  }
`;

const NavListSecondary = styled.ul`
  padding-left: var(--space-3);
  display: grid;
  margin-top: var(--space-1);
`;

const NavItem = styled(Link)`
  ${NavItemStyles}
`;

const PopoverGrid = styled.div`
  display: grid;
  grid-template-columns: var(--cols-2);
  gap: var(--space-8);

  @media ${nav} {
    grid-template-columns: var(--cols-3);
  }
`;

const PopoverGrid2 = styled(PopoverGrid)`
  @media ${nav} {
    grid-template-columns: var(--cols-2);
  }
`;

const PopoverColumn = styled.div`
  grid-column: var(--span-1);
  @media ${md} {
    grid-column: var(--span-2);
    flex-direction: column;
    display: flex;
  }
`;

const PopoverColumnLending = styled.div`
  margin-left: var(--space-n3);
  @media ${lg} {
    flex-direction: column;
    display: flex;
  }
`;

const ProductList = styled.ul`
  display: grid;
  grid-template-columns: var(--cols-1);
  list-style: none;
  column-gap: var(--space-5);
  row-gap: var(--space-1);

  @media ${md} {
    grid-template-columns: var(--cols-2);
  }
`;

const ProductListLending = styled(ProductList)`
  @media ${md} {
    grid-template-columns: var(--cols-1);
  }
`;

const NavDropdownItemStyles = css`
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: var(--space-3);
  border-radius: var(--radii-default);
  gap: var(--space-3);
  transition: 0.1s background-color ease-out;
  &:hover,
  &:focus {
    background: rgba(var(--rgb-gray-50), 0.5);
    box-shadow: 0 0 1px 0 var(--color-gray-300);
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-blue-300);
    z-index: 1;
  }
`;

const NavDropdownItem = styled(Link)`
  ${NavDropdownItemStyles}

  .icon--product {
    width: 24px;
    height: 24px;
  }

  /* ${ProductList} li:last-child & {
    cursor: not-allowed;
    &:hover {
      background: transparent;
      box-shadow: none;
    }
  } */

  ${ProductListLending} li:last-child & {
    cursor: pointer;
    &:hover {
      background: rgba(var(--rgb-gray-50), 0.5);
      box-shadow: 0 0 1px 0 var(--color-gray-300);
    }
  }
`;

const NavDropdownItemText = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--space-0-5);
`;

const NavDropdownItemTag = styled(Text)`
  display: inline-block;
  padding: var(--space-px) var(--space-2);
  color: var(--color-gray-500);
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-200);
  border-radius: var(--space-4);
  white-space: nowrap;
`;

const NavDropdownSubLink = styled(Link)`
  position: relative;
  height: 100%;
  display: flex;
  padding-top: var(--space-2);
  padding-bottom: var(--space-2);
  padding-right: var(--space-4);
  padding-left: var(--space-4);
  margin-left: var(--space-3);
  border-radius: var(--radii-default);
  gap: var(--space-3);
  color: var(--color-gray-900);
  transition: 0.1s background-color ease-out;
  &:hover,
  &:focus {
    background: rgba(var(--rgb-gray-50), 0.5);
    box-shadow: 0 0 1px 0 var(--color-gray-300);
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 0 2px var(--color-blur-300);
    z-index: 1;
  }
`;

const NavButtons = styled.div`
  display: none;
  justify-content: flex-end;

  @media ${nav} {
    display: flex;
  }
`;

const MobilePopover = styled(Popover)`
  justify-content: flex-end;
  display: flex;

  @media ${nav} {
    display: none;
  }
`;

const MobileNavOpen = styled(Popover.Button)`
  background: transparent;
  font-size: var(--text-200);
  font-weight: var(--font-medium);
`;

const MobileNavClose = styled(Popover.Button)`
  padding: var(--space-1);
  background: transparent;
`;

const MobileNavHeadingGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MobileNavHeading = styled(Heading)`
  padding-top: var(--space-1);
`;

const MobileNavList = styled.ul`
  display: grid;
  list-style: none;
  column-gap: var(--space-12);
  @media ${sm} {
    grid-columns: var(--cols-2);
  }
`;

const MobileNavListDevs = styled.ul`
  display: grid;
  column-gap: var(--space-12);
  @media ${nav} {
    grid-columns: var(--cols-2);
    grid-auto-flow: column;
  }
`;

const MobileNavListSecondary = styled.ul`
  display: grid;
  padding-top: var(--space-4);
  margin-top: var(--space-6);
  border-top-width: 1px;

  @media ${nav} {
    grid-columns: var(--cols-2);
  }
`;

const MobileNavItem = styled(Heading)`
  ${NavDropdownItemStyles}
  justify-items: space-between;
  align-items: center;
  margin-left: var(--space-n3);
`;

export const NavDropdownContainer = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  height: auto;
  top: 100%;
  padding: var(--space-2) var(--space-6) 0;

  @media ${md} {
    padding-right: var(--space-4);
    padding-left: var(--space-4);
  }

  @media ${lg} {
    left: 50%;
    margin-left: -504px;
    max-width: 1008px;
  }

  .mobile-nav-open.active + & {
    position: fixed;
    top: 0;
    padding-top: var(--space-12);
    padding-bottom: var(--space-12);
    overflow-y: scroll;
    height: 100vh;
  }
`;

export const NavDropdown = styled(motion.div)`
  border-radius: var(--space-3);
  padding: var(--space-6);
  margin-right: var(--space-4);
  margin-left: var(--space-4);
  width: 100%;
  background: rgba(var(--rgb-white), 1);
  margin: auto;
  box-shadow: 0 0 0 1px rgba(var(--rgb-1000), 0.025), 0 16px 32px rgba(var(--rgb-1000), 0.05),
    0 4px 8px rgba(var(--rgb-1000), 0.1);
  z-index: 100;
  outline: 0;
`;

const GuideIcon = styled(Icon.Guides)`
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  left: -4px;
  color: var(--color-blue-600);
`;

export const Header: React.FC<HeaderProps> = (props) => {
  const dropdownVariants = {
    hidden: {
      opacity: 0,
      y: -4,
      scale: 1,
      transition: {
        type: 'spring',
        duration: 0.2,
      },
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: 'spring',
        duration: 0.3,
      },
    },
  };

  const infrastructure = [
    {
      icon: <Icon.ProductACH />,
      name: 'ACH',
      description: 'Full access to ACH rails',
      href: '/ach',
    },
    {
      icon: <Icon.ProductWire />,
      name: 'Wire',
      description: 'Complete control of FedWire',
      href: '/wire',
    },
    {
      icon: <Icon.ProductBookTransfers />,
      name: 'Book Transfers',
      description: 'Instant transfers 24/7',
      href: '/book-transfers',
    },
    {
      icon: <Icon.ProductLedger />,
      name: 'Ledger',
      description: 'A scalable system of record',
      href: '/ledger',
    },
    {
      icon: <Icon.ProductBankAccounts />,
      name: 'Bank Accounts',
      description: 'Programmable FDIC-insured accounts',
      href: '/bank-accounts',
    },
    {
      icon: <Icon.ProductCardPrograms />,
      name: 'Card Programs',
      description: 'Customizable card network access',
      href: '/card-programs',
    },
    {
      icon: <Icon.ProductChecks />,
      name: 'Checks',
      description: 'Full customization of the check rails',
      href: '/checks',
    },
    {
      icon: <Icon.ProductFedNow />,
      name: 'FedNow',
      description: 'New real-time payment rail',
      href: '/fednow',
    },
    {
      icon: <Icon.ProductInternationalWires />,
      name: 'International Wires',
      description: 'Swift and international routing',
      href: '/international-wires',
    },
  ];

  const lending = [
    {
      icon: <Icon.ProductLoanOrigination />,
      name: 'Loan Origination',
      description: 'Flexible lending partnerships',
      href: '/loan-origination',
    },
    {
      icon: <Icon.ProductDebtFinancing />,
      name: 'Debt Financing',
      description: 'Capital to scale your lending business',
      href: '/debt-financing',
    },
    {
      icon: <Icon.ProductLoanPurchase />,
      name: 'Loan Purchase',
      description: 'Outright loan sale to Column',
      href: '/loan-purchase',
    },
  ];

  const developers = [
    {
      icon: <Icon.Documentation />,
      name: 'Documentation',
      description: 'Start building with our robust APIs',
      href: '/docs',
    },
    {
      icon: <GuideIcon viewportSize={16} />,
      name: 'Getting Started',
      description: 'Quickly get up to speed',
      href: '/docs/guides/getting-started',
    },
    {
      icon: <GuideIcon viewportSize={16} />,
      name: 'Use case guides',
      description: 'Launch financial products using our APIs',
      href: '/docs/guides#use-cases',
    },
    {
      icon: <Icon.Changelog />,
      name: 'Changelog',
      description: 'Follow the latest changes to the Column API',
      href: '/changelog',
    },
    {
      icon: <Icon.Status />,
      name: 'Status',
      description: 'Monitor our heartbeat',
      href: 'https://status.column.com',
    },
  ];

  const documentation = [
    {
      name: 'Sandbox and Testing',
      href: '/docs/guides/sandbox-and-testing',
    },
    {
      name: 'Data models',
      href: '/docs/data-models',
    },
    {
      name: 'ACH',
      href: '/docs/ach',
    },
    {
      name: 'Wire',
      href: '/docs/wire',
    },
    {
      name: 'Book Transfers and Holds',
      href: '/docs/guides/book-transfers-and-holds/',
    },
    {
      name: 'Checks',
      href: '/docs/checks',
    },
    {
      name: 'International Wires',
      href: '/docs/international-wires',
    },
  ];

  const navbar = useRef<HTMLElement>(null);
  const lastScroll = useRef<number>(0);

  const handleScroll = () => {
    if (!navbar.current) {
      return;
    }

    const scrollUp = 'scroll-up';
    const scrollDown = 'scroll-down';

    const currentScroll = window.pageYOffset;
    if (currentScroll <= 10) {
      navbar.current.classList.remove(scrollUp);
      return;
    }

    if (currentScroll > lastScroll.current && !navbar.current.classList.contains(scrollDown)) {
      // down
      navbar.current.classList.remove(scrollUp);
      navbar.current.classList.add(scrollDown);
    } else if (currentScroll < lastScroll.current && navbar.current.classList.contains(scrollDown)) {
      // up
      navbar.current.classList.remove(scrollDown);
      navbar.current.classList.add(scrollUp);
    }
    lastScroll.current = currentScroll;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Banner />
      <SiteHeader ref={navbar} {...props}>
        <HeaderContainer>
          <div>
            <HeaderLogo to="/">
              <Logo />
            </HeaderLogo>
          </div>

          <Popover.Group as="nav">
            <NavList>
              <PopoverWrapper label="Products" variants={dropdownVariants}>
                <PopoverGrid>
                  <PopoverColumn>
                    <Heading as="h4" size="300" weight="medium" mb={3}>
                      Infrastructure
                    </Heading>

                    <ProductList>
                      {infrastructure.map((item) => (
                        <li key={item.name}>
                          <NavDropdownItem to={item.href}>
                            {item.icon}
                            <NavDropdownItemText>
                              <Heading as="h6" size="200" weight="medium">
                                {item.name}
                              </Heading>
                              <Text size="100" className="text-gray-500">
                                {item.description}
                              </Text>

                              {item.beta === true ? (
                                <div>
                                  <NavDropdownItemTag size="100">Beta</NavDropdownItemTag>
                                </div>
                              ) : null}
                            </NavDropdownItemText>
                          </NavDropdownItem>
                        </li>
                      ))}
                    </ProductList>
                  </PopoverColumn>

                  <PopoverColumnLending>
                    <Heading as="h4" weight="medium" size="300" mb={3}>
                      Lending
                    </Heading>

                    <ProductListLending>
                      {lending.map((item) => (
                        <li key={item.name}>
                          <NavDropdownItem to={item.href}>
                            {item.icon}
                            <NavDropdownItemText>
                              <Heading as="h6" size="200" weight="medium">
                                {item.name}
                              </Heading>
                              <Text size="100" className="text-gray-500">
                                {item.description}
                              </Text>
                            </NavDropdownItemText>
                          </NavDropdownItem>
                        </li>
                      ))}
                    </ProductListLending>
                  </PopoverColumnLending>
                </PopoverGrid>
              </PopoverWrapper>

              <PopoverWrapper label="Developers" variants={dropdownVariants} small>
                <PopoverGrid2>
                  <div>
                    <div>
                      {developers.slice(0, 1).map((item) => (
                        <NavDropdownItem href={item.href} key={item.name}>
                          {item.icon}
                          <NavDropdownItemText>
                            <Heading as="h6" size="200" weight="medium">
                              {item.name}
                            </Heading>
                            <Text size="100" color="gray-500">
                              {item.description}
                            </Text>
                          </NavDropdownItemText>
                        </NavDropdownItem>
                      ))}
                    </div>
                    <NavListSecondary>
                      {documentation.map((item) => (
                        <li key={item.name}>
                          <NavDropdownSubLink href={item.href}>
                            <Heading as="h6" size="200" weight="medium">
                              {item.name}
                            </Heading>
                          </NavDropdownSubLink>
                        </li>
                      ))}
                    </NavListSecondary>
                  </div>
                  <ul>
                    {developers.slice(1).map((item) => (
                      <li key={item.name}>
                        <NavDropdownItem href={item.href}>
                          {item.icon}
                          <NavDropdownItemText>
                            <Heading as="h6" size="200" weight="medium">
                              {item.name}
                            </Heading>
                            <Text size="100" color="gray-500">
                              {item.description}
                            </Text>
                          </NavDropdownItemText>
                        </NavDropdownItem>
                      </li>
                    ))}
                  </ul>
                </PopoverGrid2>
              </PopoverWrapper>

              <li>
                <NavItem to="/pricing">Pricing</NavItem>
              </li>

              <li>
                <NavItem to="/blog">Blog</NavItem>
              </li>
              <li>
                <NavItem to="/company">Company</NavItem>
              </li>
            </NavList>
          </Popover.Group>

          <NavButtons>
            <ButtonGroup as="nav" gap={2}>
              <NavItem href="https://dashboard.column.com/login">Sign in</NavItem>
              <Button href="https://dashboard.column.com/register" variant="secondary" type="link">
                Get started <Icon.AnimatedArrowRight />
              </Button>
            </ButtonGroup>
          </NavButtons>

          <MobilePopover>
            {({ open }: any) => (
              <>
                <MobileNavOpen className={`mobile-nav-open ${open && 'active'}`} role="Menu">
                  <Icon.Hamburger />
                </MobileNavOpen>

                <NavDropdownContainer>
                  <AnimatePresence>
                    {open && (
                      <NavDropdown
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={dropdownVariants}
                        tabIndex={-1}
                      >
                        <MobileNavHeadingGroup>
                          <MobileNavHeading as="h4" size="300" weight="medium" color="gray-500" mb={3}>
                            Products
                          </MobileNavHeading>

                          <MobileNavClose>
                            <Icon.X />
                          </MobileNavClose>
                        </MobileNavHeadingGroup>
                        <MobileNavList>
                          {infrastructure.map((item) => (
                            <li key={item.name}>
                              <Link to={item.href}>
                                <MobileNavItem size="200" weight="medium">
                                  {item.name}
                                </MobileNavItem>
                              </Link>
                            </li>
                          ))}
                          {lending.map((item) => (
                            <li key={item.name}>
                              <Link href={item.href}>
                                <MobileNavItem size="200" weight="medium">
                                  {item.name}
                                </MobileNavItem>
                              </Link>
                            </li>
                          ))}
                        </MobileNavList>

                        <MobileNavHeading as="h4" size="300" weight="medium" color="gray-500" mt={4} mb={3}>
                          Developers
                        </MobileNavHeading>

                        <MobileNavListDevs>
                          {developers.map((item) => (
                            <li key={item.name}>
                              <Link to={item.href}>
                                <MobileNavItem size="200" weight="medium">
                                  {item.name}
                                </MobileNavItem>
                              </Link>
                            </li>
                          ))}
                        </MobileNavListDevs>

                        <MobileNavListSecondary>
                          <li>
                            <Link to="/blog">
                              <MobileNavItem size="200" weight="medium">
                                Blog
                              </MobileNavItem>
                            </Link>
                          </li>
                          <li>
                            <Link to="/company">
                              <MobileNavItem size="200" weight="medium">
                                Company
                              </MobileNavItem>
                            </Link>
                          </li>
                        </MobileNavListSecondary>
                      </NavDropdown>
                    )}
                  </AnimatePresence>
                </NavDropdownContainer>
              </>
            )}
          </MobilePopover>
        </HeaderContainer>
      </SiteHeader>
    </>
  );
};
