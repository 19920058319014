import React from 'react';
import styled from 'styled-components';
import { Link } from '~/elements/Link';
import { Heading } from '~/elements/Heading';

export interface BannerProps {
  className?: string;
}

const Wrapper = styled.div`
  background-color: var(--color-cyan-800);
  padding: 8px 16px;
  color: var(--color-white);
  position: relative;
  z-index: 1;
  text-align: center;
  line-height: 16px;
`;

export const Banner: React.FC<BannerProps> = (props) => {
  return (
    <Wrapper>
      <Link to="/fednow">
        <Heading as="h4" size="200" weight="medium" color="white">
          Introducing FedNow — sign up for early access
        </Heading>
      </Link>
    </Wrapper>
  );
};
