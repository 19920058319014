import React from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { Hero } from '~/components/Hero';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';
import { Code } from '~/elements/Code/Code';
import { Button } from '~/elements/Button';
import { ButtonGroup } from '~/elements/ButtonGroup';
import { Icon } from '~/elements/Icon';
import { Typing } from '~/elements/Typing';
import { sm } from '~/ds/mixins/breakpoints';

const CodeSample = styled(Code)`
  position: relative;
  margin-top: -24px;

  > div:nth-child(1) {
    padding-top: 12px;
  }
  @media ${sm} {
    margin-top: 0;

    > div:nth-child(1) {
      padding-top: 0;
    }
  }
`;

export const SectionHero: React.FC = () => {
  const [gradientRef, heroInView] = useInView({
    threshold: 0,
  });

  const heroClasses: ConditionalClasses = {
    'inview--gradient': heroInView,
  };

  return (
    <Hero palette="primary" className={classNames(heroClasses)}>
      <div className="relative z-10 max-w-screen-sm">
        <Heading as="h4" size="300" weight="medium" mb={3} color="gray-800" eyebrow>
          Column N.A. Member FDIC
        </Heading>
        <Heading as="h1" size="1000" weight="semibold" mt={8}>
          The developer
          <br />
          infrastructure bank
        </Heading>
        <Text mt={2} size="500">
          The only nationally chartered bank built to enable developers and builders to cre
          <Typing text="ate new financial products" />
        </Text>
        <ButtonGroup mt={4} flexDirection={{ _: 'column', sm: 'row' }}>
          <Button href="https://dashboard.column.com/register" type="link" variant="primary">
            Start building <Icon.AnimatedArrowRight />
          </Button>
          <Button to="/docs" variant="secondary">
            Read documentation
          </Button>
        </ButtonGroup>
      </div>

      <div className="relative z-10 grid grid-cols-12 mt-12 md:gap-8">
        <div className="col-span-12 md:col-span-11">
          <div className="overflow-hidden rounded shadow-product-border">
            <picture>
              <source srcSet="images/index/hero.webp, images/index/hero@2x.webp 2x" type="image/webp" />
              <source srcSet="images/index/hero.jpg, images/index/hero@2x.jpg 2x" type="image/jpeg" />
              <img src="/images/index/hero.jpg" width="972px" height="480px" alt="Dashboard Transfer Overview" />
            </picture>
          </div>
        </div>
        <div className="absolute inset-0">
          <div className="grid h-full grid-cols-12 md:gap-8">
            <div className="grid items-start justify-center col-span-10 col-start-2 translate-y-1/2 md:col-end-13 md:col-span-6 sm:items-center lg:items-start sm:translate-y-0 lg:-mt-12">
              <CodeSample copy={false}>
                {`curl 'https://api.column.com/transfers/wire' \\
    -u :prod_1vWeqakwT2N7NYRtZkUPSBt4scn \\
    -d counterparty_id=cpty_1vWeqakwT2N7NYRtZkUPSBt4scn \\
    -d bank_account_id=bacc_1vWeqakwT2N7NYRtZkUPSBt4scn \\
    -d amount=40000 \\
    -d description="Visa daily settlement"`}
              </CodeSample>
            </div>
          </div>
        </div>
      </div>

      <div className="absolute inset-0 overflow-hidden -bottom-20 -top-20">
        <div className="background-gradient" ref={gradientRef}>
          <div className="background-gradient-pattern" />
        </div>
      </div>
    </Hero>
  );
};

export default SectionHero;
