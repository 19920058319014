import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Layout } from '~/components/docs/Layout';
import { Container } from '~/elements/Container';
import { Grid } from '~/elements/Grid';
import { Box } from '~/elements/Box';
import { Column } from '~/elements/Column';
import { MarkdownStyles } from '~/elements/Markdown';
import { Button } from '~/elements/Button';
import { Status } from '~/elements/Status';
import { Heading } from '~/elements/Heading';
import { Text } from '~/elements/NewText';

const StatusComponent = styled(Status)`
  border: 0;
  padding: 0;
  margin-top: var(--space-3);
  font-weight: var(--font-medium);
  font-size: var(--text-300);
  color: var(--color-gray-900);
  transform: translateX(0);
`;

const StatusBox = styled.div`
  background-color: var(--color-white);
  padding: var(--space-6);
  border-radius: var(--space-3);
  top: var(--space-4);
  flex-direction: column;
  display: flex;
  box-shadow: 0 0 0 1px var(--color-gray-100), 0 1px 3px rgba(var(--rgb-black), 0.05);
  transition: box-shadow 0.2s;

  &:hover {
    box-shadow: 0 0 0 1px var(--color-gray-200), 0 4px 6px rgba(var(--rgb-black), 0.1);
  }
`;
const DocsIndexPage: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>Column Documentation</title>
      </Helmet>

      <Container pt={12} mt="62px">
        <Grid>
          <Column gridColumn={{ _: 12, lg: 8 }}>
            <Heading as="h1" size="600" color="blue-900">
              Documentation
            </Heading>
            <Text size="300" mt={4} color="blue-900">
              Column N.A. is the first nationally chartered bank created to serve developers and builders, offering
              financial capabilities in a developer first way.
            </Text>

            <MarkdownStyles mt={4}>
              Our mission is to enable you to have the full power of a nationally chartered bank in as lightweight a
              format as possible. We have built our own ledger, core, and direct integration into the Federal Reserve.
              Our goal is to expose these capabilities to you in a simple, powerful and developer-friendly way.
            </MarkdownStyles>
          </Column>
        </Grid>
      </Container>

      <Container py={12}>
        <Grid gap={8}>
          <Column gridColumn={{ _: 12, lg: 8 }}>
            <Heading as="h4" size="400" weight="medium">
              Welcome
            </Heading>
            <MarkdownStyles mt={4}>
              <p>
                We continue building as you build and your feedback is welcome (and pleaded for)! Honestly...tell us
                everything, the more critical the better. We want to hear what you hate, what you like and what you
                want.
              </p>
              <p>
                For feedback email us at <a href="mailto:developers@column.com">developers@column.com</a>. For technical
                support email us at <a href="mailto:support@column.com">support@column.com</a>, and if you want to
                complain to the CEO (or say nice things), you can email me at{' '}
                <a href="mailto:william@column.com">william@column.com</a> Its been a labor of love and we hope you find
                it useful!
              </p>
            </MarkdownStyles>
            <Button
              variant="primary"
              size="default"
              type="link"
              href="https://dashboard.column.com/contact"
              className="mt-4"
            >
              Contact us
            </Button>
          </Column>
          <Column gridColumn={{ _: 12, lg: 4 }}>
            <StatusBox>
              <Heading as="h4" size="300">
                Status
              </Heading>
              <StatusComponent />
              <Box display="block" mt={2} as="a" href="https://status.column.com/uptime">
                <Text size="200" color="gray-500">
                  See status history
                </Text>
              </Box>
            </StatusBox>
          </Column>
        </Grid>
      </Container>
    </Layout>
  );
};

export default DocsIndexPage;
